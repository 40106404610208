import React from 'react';

const Conditions = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#ffffff' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Terms and Conditions</h1>
      
      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>1. Introduction</h2>
      <p style={{ fontSize: '16px' }}>
        Welcome to barterout! By accessing or using our platform, you agree to comply with these Terms and Conditions, as well as any future modifications.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>2. User Responsibilities</h2>
      <p style={{ fontSize: '16px' }}>
        Users must be at least 18 years old to use barterout. You are responsible for maintaining the confidentiality of your account information and ensuring the security of your account.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>3. Swap and Listing Guidelines</h2>
      <p style={{ fontSize: '16px' }}>
        All items listed for swapping must be legally owned by the user and should not violate any third-party rights. Users must describe items accurately and ensure they meet the platform's guidelines for acceptable items.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>4. Prohibited Activities</h2>
      <p style={{ fontSize: '16px' }}>
        Users are prohibited from posting items that are illegal, counterfeit, or harmful. Users must also avoid any activity that may disrupt or harm the integrity of the barterout platform.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>5. Account Termination</h2>
      <p style={{ fontSize: '16px' }}>
        barterout reserves the right to suspend or terminate user accounts if the user is found to be violating the platform's rules or if they engage in any fraudulent activity.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>6. Limitation of Liability</h2>
      <p style={{ fontSize: '16px' }}>
        barterout is not responsible for any disputes or damages resulting from transactions between users. Users agree to resolve any disputes independently and hold barterout harmless.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>7. Changes to the Terms</h2>
      <p style={{ fontSize: '16px' }}>
        We may update these Terms and Conditions from time to time. You will be notified of any significant changes. Continued use of the platform constitutes acceptance of the new terms.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>8. Contact Information</h2>
      <p style={{ fontSize: '16px' }}>
        If you have any questions or concerns about these terms, please contact us at <a href="mailto:support@barterout.com">support@barterout.com</a>.
      </p>
    </div>
  );
};

export default Conditions;

