import React, { useState, useEffect } from 'react';
import axios from './axiosInstance';
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function Browse() {
  const [items, setItems] = useState([]);
  const [revealedContacts, setRevealedContacts] = useState({});
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const category = queryParams.get('category') || '';
    const loc = queryParams.get('location') || '';

    axios.get('/items', {
      params: { search, category, location: loc },
    })
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.error('Error fetching items:', error);
      });
  }, [location.search]);

  const maskContactInfo = (contact) => {
    if (!contact) return '';
    if (contact.includes('@')) {
      const [localPart, domain] = contact.split('@');
      const maskedLocal = localPart.length > 2 ? localPart.substring(0, 2) + '***' : localPart;
      return `${maskedLocal}@${domain}`;
    } else {
      return contact.length > 4 ? contact.substring(0, 4) + '****' : contact;
    }
  };

  const handleRevealContact = (itemId) => {
    setRevealedContacts((prev) => ({ ...prev, [itemId]: true }));
  };

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <Breadcrumb />
      <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
        {t('browse_items')}
      </h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {items.map((item) => (
          <div
            key={item._id}
            className="bordered-text"
            style={{
              width: '200px',
              padding: '5px',
              fontSize: '14px',
              backgroundColor: '#FFFFFF',
            }}
          >
            {item.photo && (
              <img
                src={`https://localhost:3000${item.photo}`}
                alt={item.name}
                style={{ width: '100%', height: '150px', objectFit: 'cover', marginBottom: '5px' }}
              />
            )}
            <p style={{ margin: '0 0 5px 0', fontWeight: 'bold' }}>{item.name}</p>
            <p style={{ margin: '0 0 5px 0' }}>{item.description}</p>
            <p style={{ margin: '0 0 5px 0' }}><strong>{t('category')}:</strong> {item.category}</p>
            <p style={{ margin: '0 0 5px 0' }}><strong>{t('location')}:</strong> {item.location}</p>
            {item.contactInfo && (
              <div style={{ margin: '0 0 5px 0' }}>
                <strong>{t('contact_info')}:</strong>{' '}
                {revealedContacts[item._id] ? (
                  item.contactInfo
                ) : (
                  <>
                    {maskContactInfo(item.contactInfo)}{' '}
                    <button
                      onClick={() => handleRevealContact(item._id)}
                      style={{
                        padding: '2px 5px',
                        fontSize: '12px',
                        backgroundColor: '#E6E6E6',
                        border: '1px solid #000000',
                        cursor: 'pointer',
                      }}
                    >
                      {t('reveal_contact')}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Browse;
