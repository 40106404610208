import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "barterout": "barterout",
      "search_barterout": "Search barterout",
      "list": "List",
      "suggestions": "Suggestions",
      "conditions": "Conditions",
      "privacy": "Privacy",
      "sign_in": "Sign In",
      "create_account": "Create Account",
      "my_account": "My Account",
      "sign_out": "Sign Out",
      "english": "English",
      "latvian": "Latvian",
      "estonian": "Estonian",
      "lithuanian": "Lithuanian",
      "finnish": "Finnish",
      "categories": "Categories",
      "electronics": "Electronics",
      "clothing": "Clothing",
      "books": "Books",
      "antiques": "Antiques",
      "arts": "Arts",
      "appliances": "Appliances",
      "properties": "Properties",
      "jewelries": "Jewelries",
      "furniture": "Furniture",
      "toys": "Toys",
      "sports": "Sports",
      "other": "Other",
      "all_locations": "All Locations",
      "browse_items": "Browse Items",
      "category": "Category",
      "location": "Location",
      "contact_info": "Contact Info",
      "reveal_contact": "Reveal Contact",
      "create_a_posting": "Create a Posting",
      "item_name": "Item Name",
      "description": "Description",
      "select_category": "Select Category",
      "photo": "Photo",
      "continue": "Continue",
      "item_listed": "Item listed!",
      "failed_to_list_item": "Failed to list item.",
      "phone_or_email": "phone or email",
      "optional": "optional",
      "invalid_contact_info": "Invalid contact info. Must be a valid email or phone number.",
      "trade_suggestions": "Trade Suggestions",
      "conditions_of_use": "Conditions of Use",
      "privacy_policy": "Privacy Policy",
      "username": "Username",
      "password": "Password",
      "logged_in": "Logged in!",
      "login_failed": "Login failed.",
      "new_to_barterout": "New to barterout?",
      "account_created": "Account created! Please sign in.",
      "registration_failed": "Registration failed.",
      "already_have_account": "Already have an account?",
      "account_info": "Account Info",
      "country": "Country",
      "edit_profile": "Edit Profile",
      "save": "Save",
      "cancel": "Cancel",
      "profile_updated": "Profile updated successfully.",
      "profile_update_failed": "Failed to update profile.",
      "change_password": "Change Password",
      "new_password": "New Password",
      "confirm_password": "Confirm Password",
      "passwords_do_not_match": "Passwords do not match.",
      "password_changed": "Password changed successfully.",
      "password_change_failed": "Failed to change password.",
      "listings": "Listings",
      "active_listings": "Active Listings",
      "inactive_listings": "Inactive Listings",
      "no_listings": "No listings.",
      "edit": "Edit",
      "delete": "Delete",
      "delete_confirm": "Are you sure you want to delete this listing?",
      "listing_updated": "Listing updated successfully.",
      "listing_update_failed": "Failed to update listing.",
      "listing_deleted": "Listing deleted successfully.",
      "listing_delete_failed": "Failed to delete listing.",
      "deactivate_account": "Deactivate Account",
      "deactivate_confirm": "Are you sure you want to deactivate your account? This will also deactivate all your listings.",
      "deactivated_success": "Account deactivated successfully.",
      "deactivation_failed": "Failed to deactivate account.",
      "my_listings": "My Listings",
      "no_active_listings": "No active listings.",
      "no_inactive_listings": "No inactive listings."
    },
  },
  lv: {
    translation: {
      "barterout": "barterout",
      "search_barterout": "Meklēt barterout",
      "list": "Izveidot sludinājumu",
      "suggestions": "Ieteikumi",
      "conditions": "Lietošanas noteikumi",
      "privacy": "Privātuma politika",
      "sign_in": "Pierakstīties",
      "create_account": "Izveidot kontu",
      "my_account": "Mans konts",
      "sign_out": "Izrakstīties",
      "english": "Angļu",
      "latvian": "Latviešu",
      "estonian": "Igauņu",
      "lithuanian": "Lietuviešu",
      "finnish": "Somu",
      "categories": "Kategorijas",
      "electronics": "Elektronika",
      "clothing": "Apģērbs",
      "books": "Grāmatas",
      "antiques": "Antikvāri priekšmeti",
      "arts": "Māksla",
      "appliances": "Mājsaimniecības tehnika",
      "properties": "Īpašumi",
      "jewelries": "Rotaslietas",
      "furniture": "Mēbeles",
      "toys": "Rotaļlietas",
      "sports": "Sports",
      "other": "Cits",
      "all_locations": "Visas vietas",
      "browse_items": "Pārlūkot sludinājumus",
      "category": "Kategorija",
      "location": "Atrašanās vieta",
      "contact_info": "Kontaktinformācija",
      "reveal_contact": "Atklāt kontaktinformāciju",
      "create_a_posting": "Izveidot sludinājumu",
      "item_name": "Priekšmeta nosaukums",
      "description": "Apraksts",
      "select_category": "Izvēlieties kategoriju",
      "photo": "Foto",
      "continue": "Turpināt",
      "item_listed": "Sludinājums izveidots!",
      "failed_to_list_item": "Neizdevās izveidot sludinājumu.",
      "phone_or_email": "tālrunis vai e-pasts",
      "optional": "neobligāts",
      "invalid_contact_info": "Nederīga kontaktinformācija. Jābūt derīgam e-pastam vai tālruņa numuram.",
      "trade_suggestions": "Maiņas ieteikumi",
      "conditions_of_use": "Lietošanas noteikumi",
      "privacy_policy": "Privātuma politika",
      "username": "Lietotājvārds",
      "password": "Parole",
      "logged_in": "Pierakstīšanās veiksmīga!",
      "login_failed": "Pierakstīšanās neizdevās.",
      "new_to_barterout": "Jauns barterout lietotājs?",
      "account_created": "Konts izveidots! Lūdzu, pierakstieties.",
      "registration_failed": "Reģistrācija neizdevās.",
      "already_have_account": "Jau ir konts?",
      "account_info": "Konta informācija",
      "country": "Valsts",
      "edit_profile": "Rediģēt profilu",
      "save": "Saglabāt",
      "cancel": "Atcelt",
      "profile_updated": "Profils veiksmīgi atjaunināts.",
      "profile_update_failed": "Neizdevās atjaunināt profilu.",
      "change_password": "Mainīt paroli",
      "new_password": "Jaunā parole",
      "confirm_password": "Apstiprināt paroli",
      "passwords_do_not_match": "Paroles nesakrīt.",
      "password_changed": "Parole veiksmīgi nomainīta.",
      "password_change_failed": "Neizdevās nomainīt paroli.",
      "listings": "Sludinājumi",
      "active_listings": "Aktīvie sludinājumi",
      "inactive_listings": "Neaktīvie sludinājumi",
      "no_listings": "Nav sludinājumu.",
      "edit": "Rediģēt",
      "delete": "Dzēst",
      "delete_confirm": "Vai tiešām vēlaties dzēst šo sludinājumu?",
      "listing_updated": "Sludinājums veiksmīgi atjaunināts.",
      "listing_update_failed": "Neizdevās atjaunināt sludinājumu.",
      "listing_deleted": "Sludinājums veiksmīgi dzēsts.",
      "listing_delete_failed": "Neizdevās dzēst sludinājumu.",
      "deactivate_account": "Deaktivizēt kontu",
      "deactivate_confirm": "Vai tiešām vēlaties deaktivizēt savu kontu? Tas deaktivizēs arī visus jūsu sludinājumus.",
      "deactivated_success": "Konts veiksmīgi deaktivizēts.",
      "deactivation_failed": "Neizdevās deaktivizēt kontu.",
      "my_listings": "Mani sludinājumi",
      "no_active_listings": "Nav aktīvu sludinājumu.",
      "no_inactive_listings": "Nav neaktīvu sludinājumu.",
    },
  },
  et: {
    translation: {
      "barterout": "barterout",
      "search_barterout": "Otsi barterout",
      "list": "Lisa kuulutus",
      "suggestions": "Soovitused",
      "conditions": "Kasutustingimused",
      "privacy": "Privaatsuspoliitika",
      "sign_in": "Logi sisse",
      "create_account": "Loo konto",
      "my_account": "Minu konto",
      "sign_out": "Logi välja",
      "english": "Inglise",
      "latvian": "Läti",
      "estonian": "Eesti",
      "lithuanian": "Leedu",
      "finnish": "Soome",
      "categories": "Kategooriad",
      "electronics": "Elektroonika",
      "clothing": "Riided",
      "books": "Raamatud",
      "antiques": "Antiikesemed",
      "arts": "Kunst",
      "appliances": "Kodumasinad",
      "properties": "Kinnisvara",
      "jewelries": "Ehted",
      "furniture": "Mööbel",
      "toys": "Mänguasjad",
      "sports": "Sport",
      "other": "Muu",
      "all_locations": "Kõik asukohad",
      "browse_items": "Sirvi kuulutusi",
      "category": "Kategooria",
      "location": "Asukoht",
      "contact_info": "Kontaktinfo",
      "reveal_contact": "Näita kontaktinfot",
      "create_a_posting": "Loo kuulutus",
      "item_name": "Eseme nimi",
      "description": "Kirjeldus",
      "select_category": "Vali kategooria",
      "photo": "Foto",
      "continue": "Jätka",
      "item_listed": "Kuulutus lisatud!",
      "failed_to_list_item": "Kuulutuse lisamine ebaõnnestus.",
      "phone_or_email": "telefon või e-post",
      "optional": "valikuline",
      "invalid_contact_info": "Vigane kontaktinfo. Peab olema kehtiv e-post või telefoninumber.",
      "trade_suggestions": "Vahetussoovitused",
      "conditions_of_use": "Kasutustingimused",
      "privacy_policy": "Privaatsuspoliitika",
      "username": "Kasutajanimi",
      "password": "Parool",
      "logged_in": "Sisselogimine õnnestus!",
      "login_failed": "Sisselogimine ebaõnnestus.",
      "new_to_barterout": "Uus barteroutis?",
      "account_created": "Konto loodud! Palun logi sisse.",
      "registration_failed": "Registreerimine ebaõnnestus.",
      "already_have_account": "Juba on konto?",
      "account_info": "Konto info",
      "country": "Riik",
      "edit_profile": "Muuda profiili",
      "save": "Salvesta",
      "cancel": "Tühista",
      "profile_updated": "Profiil edukalt uuendatud.",
      "profile_update_failed": "Profiili uuendamine ebaõnnestus.",
      "change_password": "Muuda parooli",
      "new_password": "Uus parool",
      "confirm_password": "Kinnita parool",
      "passwords_do_not_match": "Paroolid ei kattu.",
      "password_changed": "Parool edukalt muudetud.",
      "password_change_failed": "Parooli muutmine ebaõnnestus.",
      "listings": "Kuulutused",
      "active_listings": "Aktiivsed kuulutused",
      "inactive_listings": "Mitteaktiivsed kuulutused",
      "no_listings": "Kuulutusi pole.",
      "edit": "Muuda",
      "delete": "Kustuta",
      "delete_confirm": "Kas olete kindel, et soovite selle kuulutuse kustutada?",
      "listing_updated": "Kuulutus edukalt uuendatud.",
      "listing_update_failed": "Kuulutuse uuendamine ebaõnnestus.",
      "listing_deleted": "Kuulutus edukalt kustutatud.",
      "listing_delete_failed": "Kuulutuse kustutamine ebaõnnestus.",
      "deactivate_account": "Deaktiveeri konto",
      "deactivate_confirm": "Kas olete kindel, et soovite oma konto deaktiveerida? See deaktiveerib ka kõik teie kuulutused.",
      "deactivated_success": "Konto edukalt deaktiveeritud.",
      "deactivation_failed": "Konto deaktiveerimine ebaõnnestus.",
      "my_listings": "Minu kuulutused",
      "no_active_listings": "Aktiivseid kuulutusi pole.",
      "no_inactive_listings": "Mitteaktiivseid kuulutusi pole."      
    },
  },
  lt: {
    translation: {
      "barterout": "barterout",
      "search_barterout": "Ieškoti barterout",
      "list": "Sukurti skelbimą",
      "suggestions": "Pasiūlymai",
      "conditions": "Naudojimo sąlygos",
      "privacy": "Privatumo politika",
      "sign_in": "Prisijungti",
      "create_account": "Sukurti paskyrą",
      "my_account": "Mano paskyra",
      "sign_out": "Atsijungti",
      "english": "Anglų",
      "latvian": "Latvių",
      "estonian": "Estų",
      "lithuanian": "Lietuvių",
      "finnish": "Suomių",
      "categories": "Kategorijos",
      "electronics": "Elektronika",
      "clothing": "Drabužiai",
      "books": "Knygos",
      "antiques": "Antikvariniai daiktai",
      "arts": "Menas",
      "appliances": "Buitinė technika",
      "properties": "Nekilnojamasis turtas",
      "jewelries": "Papuošalai",
      "furniture": "Baldai",
      "toys": "Žaislai",
      "sports": "Sportas",
      "other": "Kita",
      "all_locations": "Visos vietos",
      "browse_items": "Peržiūrėti skelbimus",
      "category": "Kategorija",
      "location": "Vieta",
      "contact_info": "Kontaktinė informacija",
      "reveal_contact": "Rodyti kontaktinę informaciją",
      "create_a_posting": "Sukurti skelbimą",
      "item_name": "Daikto pavadinimas",
      "description": "Aprašymas",
      "select_category": "Pasirinkite kategoriją",
      "photo": "Nuotrauka",
      "continue": "Tęsti",
      "item_listed": "Skelbimas sukurtas!",
      "failed_to_list_item": "Nepavyko sukurti skelbimo.",
      "phone_or_email": "telefonas arba el. paštas",
      "optional": "neprivaloma",
      "invalid_contact_info": "Neteisinga kontaktinė informacija. Turi būti galiojantis el. paštas arba telefono numeris.",
      "trade_suggestions": "Mainų pasiūlymai",
      "conditions_of_use": "Naudojimo sąlygos",
      "privacy_policy": "Privatumo politika",
      "username": "Vartotojo vardas",
      "password": "Slaptažodis",
      "logged_in": "Prisijungimas sėkmingas!",
      "login_failed": "Prisijungimas nepavyko.",
      "new_to_barterout": "Naujas barterout vartotojas?",
      "account_created": "Paskyra sukurta! Prašome prisijungti.",
      "registration_failed": "Registracija nepavyko.",
      "already_have_account": "Jau turite paskyrą?",
      "account_info": "Paskyros informacija",
      "country": "Šalis",
      "edit_profile": "Redaguoti profilį",
      "save": "Išsaugoti",
      "cancel": "Atšaukti",
      "profile_updated": "Profilis sėkmingai atnaujintas.",
      "profile_update_failed": "Nepavyko atnaujinti profilio.",
      "change_password": "Keisti slaptažodį",
      "new_password": "Naujas slaptažodis",
      "confirm_password": "Patvirtinti slaptažodį",
      "passwords_do_not_match": "Slaptažodžiai nesutampa.",
      "password_changed": "Slaptažodis sėkmingai pakeistas.",
      "password_change_failed": "Nepavyko pakeisti slaptažodžio.",
      "listings": "Skelbimai",
      "active_listings": "Aktyvūs skelbimai",
      "inactive_listings": "Neaktyvūs skelbimai",
      "no_listings": "Nėra skelbimų.",
      "edit": "Redaguoti",
      "delete": "Ištrinti",
      "delete_confirm": "Ar tikrai norite ištrinti šį skelbimą?",
      "listing_updated": "Skelbimas sėkmingai atnaujintas.",
      "listing_update_failed": "Nepavyko atnaujinti skelbimo.",
      "listing_deleted": "Skelbimas sėkmingai ištrintas.",
      "listing_delete_failed": "Nepavyko ištrinti skelbimo.",
      "deactivate_account": "Deaktyvuoti paskyrą",
      "deactivate_confirm": "Ar tikrai norite deaktyvuoti savo paskyrą? Tai taip pat deaktyvuos visus jūsų skelbimus.",
      "deactivated_success": "Paskyra sėkmingai deaktyvuota.",
      "deactivation_failed": "Nepavyko deaktyvuoti paskyros.",
      "my_listings": "Mano skelbimai",
      "no_active_listings": "Nėra aktyvių skelbimų.",
      "no_inactive_listings": "Nėra neaktyvių skelbimų.",
    },
  },
  fi: {
    translation: {
      "barterout": "barterout",
      "search_barterout": "Hae barterout",
      "list": "Lisää ilmoitus",
      "suggestions": "Ehdotukset",
      "conditions": "Käyttöehdot",
      "privacy": "Tietosuojakäytäntö",
      "sign_in": "Kirjaudu sisään",
      "create_account": "Luo tili",
      "my_account": "Oma tili",
      "sign_out": "Kirjaudu ulos",
      "english": "Englanti",
      "latvian": "Latvia",
      "estonian": "Viro",
      "lithuanian": "Liettua",
      "finnish": "Suomi",
      "categories": "Kategoriat",
      "electronics": "Elektroniikka",
      "clothing": "Vaatteet",
      "books": "Kirjat",
      "antiques": "Antiikki",
      "arts": "Taide",
      "appliances": "Kodinkoneet",
      "properties": "Kiinteistöt",
      "jewelries": "Korut",
      "furniture": "Huonekalut",
      "toys": "Lelut",
      "sports": "Urheilu",
      "other": "Muu",
      "all_locations": "Kaikki sijainnit",
      "browse_items": "Selaa ilmoituksia",
      "category": "Kategoria",
      "location": "Sijainti",
      "contact_info": "Yhteystiedot",
      "reveal_contact": "Näytä yhteystiedot",
      "create_a_posting": "Luo ilmoitus",
      "item_name": "Tuotteen nimi",
      "description": "Kuvaus",
      "select_category": "Valitse kategoria",
      "photo": "Kuva",
      "continue": "Jatka",
      "item_listed": "Ilmoitus lisätty!",
      "failed_to_list_item": "Ilmoituksen lisääminen epäonnistui.",
      "phone_or_email": "puhelin tai sähköposti",
      "optional": "valinnainen",
      "invalid_contact_info": "Virheelliset yhteystiedot. Pitää olla kelvollinen sähköposti tai puhelinnumero.",
      "trade_suggestions": "Vaihtoehdotukset",
      "conditions_of_use": "Käyttöehdot",
      "privacy_policy": "Tietosuojakäytäntö",
      "username": "Käyttäjätunnus",
      "password": "Salasana",
      "logged_in": "Kirjautuminen onnistui!",
      "login_failed": "Kirjautuminen epäonnistui.",
      "new_to_barterout": "Uusi barterout-käyttäjä?",
      "account_created": "Tili luotu! Kirjaudu sisään.",
      "registration_failed": "Rekisteröinti epäonnistui.",
      "already_have_account": "Onko sinulla jo tili?",
      "account_info": "Tilin tiedot",
      "country": "Maa",
      "edit_profile": "Muokkaa profiilia",
      "save": "Tallenna",
      "cancel": "Peruuta",
      "profile_updated": "Profiili päivitetty onnistuneesti.",
      "profile_update_failed": "Profiilin päivitys epäonnistui.",
      "change_password": "Vaihda salasana",
      "new_password": "Uusi salasana",
      "confirm_password": "Vahvista salasana",
      "passwords_do_not_match": "Salasanat eivät täsmää.",
      "password_changed": "Salasana vaihdettu onnistuneesti.",
      "password_change_failed": "Salasanan vaihto epäonnistui.",
      "listings": "Ilmoitukset",
      "active_listings": "Aktiiviset ilmoitukset",
      "inactive_listings": "Epäaktiiviset ilmoitukset",
      "no_listings": "Ei ilmoituksia.",
      "edit": "Muokkaa",
      "delete": "Poista",
      "delete_confirm": "Haluatko varmasti poistaa tämän ilmoituksen?",
      "listing_updated": "Ilmoitus päivitetty onnistuneesti.",
      "listing_update_failed": "Ilmoituksen päivitys epäonnistui.",
      "listing_deleted": "Ilmoitus poistettu onnistuneesti.",
      "listing_delete_failed": "Ilmoituksen poisto epäonnistui.",
      "deactivate_account": "Poista tili käytöstä",
      "deactivate_confirm": "Haluatko varmasti poistaa tilisi käytöstä? Tämä poistaa myös kaikki ilmoituksesi käytöstä.",
      "deactivated_success": "Tili poistettu käytöstä onnistuneesti.",
      "deactivation_failed": "Tilin poisto käytöstä epäonnistui.",
      "my_listings": "Omat ilmoitukset",
      "no_active_listings": "Ei aktiivisia ilmoituksia.",
      "no_inactive_listings": "Ei ei-aktiivisia ilmoituksia.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources, // Your translations
    fallbackLng: 'en', // Default language if detection fails
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ['localStorage', 'navigator'], // First check localStorage, then navigator (browser language)
      caches: ['localStorage'], // Cache language in localStorage
    },
  });

export default i18n;
