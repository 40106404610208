import React, { useState, useEffect } from 'react';
import axios from './axiosInstance';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Account() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({ username: '', country: '' });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [message, setMessage] = useState('');
  const [listings, setListings] = useState({ active: [], inactive: [] });
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editCategory, setEditCategory] = useState('');
  const [editLocation, setEditLocation] = useState('');
  const [editContactInfo, setEditContactInfo] = useState('');
  const currentLang = i18n.language;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login'); // Redirect to login page if no token is found
    } else {
      axios.get('/Account', {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          setUserInfo(response.data);
          setNewUsername(response.data.username);
          setNewCountry(response.data.country || '');
        })
        .catch((error) => {
          console.error('Failed to fetch user info:', error);
          setMessage(t('failed_to_load_user_info'));
        });

      axios.get('/Account/listings', {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          setListings(response.data);
        })
        .catch((error) => {
          console.error('Failed to fetch listings:', error);
          setMessage(t('failed_to_load_listings'));
        });
    }
  }, [navigate, t]);

  const handleDeactivate = () => {
    if (window.confirm(t('deactivate_confirm'))) {
      axios.post('/deactivate', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
        .then(() => {
          setMessage(t('deactivated_success'));
          localStorage.removeItem('token');
          navigate('/login');
        })
        .catch((error) => {
          console.error('Deactivation failed:', error);
          setMessage(t('deactivation_failed'));
        });
    }
  };

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      setMessage(t('passwords_do_not_match'));
      return;
    }
    axios.post('/Account', { newPassword }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then(() => {
        setMessage(t('password_changed'));
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((error) => {
        console.error('Password change failed:', error);
        setMessage(t('password_change_failed'));
      });
  };

  const handleEditProfile = () => {
    axios.put('/Account', { username: newUsername, country: newCountry }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((response) => {
        setUserInfo({ username: newUsername, country: newCountry });
        setMessage(t('profile_updated'));
        setEditMode(false);
      })
      .catch((error) => {
        console.error('Profile update failed:', error);
        setMessage(t('profile_update_failed'));
      });
  };

  const handleDeleteListing = (itemId) => {
    if (window.confirm(t('delete_confirm'))) {
      axios.delete(`/items/${itemId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
        .then(() => {
          setListings((prev) => ({
            active: prev.active.filter((item) => item._id !== itemId),
            inactive: prev.inactive,
          }));
          setMessage(t('listing_deleted'));
        })
        .catch((error) => {
          console.error('Listing deletion failed:', error);
          setMessage(t('listing_delete_failed'));
        });
    }
  };
  
    const token = localStorage.getItem('token');
    const listingData = {
      name: editName,
      description: editDescription,
      category: editCategory,
      location: editLocation,
      contactInfo: editContactInfo,
    };

    axios.post('/Account/listings', listingData, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setListings((prev) => ({
          active: [...prev.active, response.data],
          inactive: prev.inactive,
        }));
        setMessage(t('listing_added'));
        setEditName('');
        setEditDescription('');
        setEditCategory('');
        setEditLocation('');
        setEditContactInfo('');
      })
      .catch((error) => {
        console.error('Listing saving failed:', error);
        setMessage(t('listing_add_failed'));
      });
  

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <Helmet>
        <title>{t('barterout')} - {t('my_account')}</title>
        <meta name="description" content={t('my_account') + ' on ' + t('barterout') + ': manage your profile, listings, and settings in Riga, Tallinn, Vilnius, Helsinki.'} />
        <meta name="keywords" content="my account, manage listings, barterout, trade items, Riga, Tallinn, Vilnius, Helsinki" />
        <link rel="canonical" href={`https://barterout.com/${currentLang}/account`} />
      </Helmet>
      <h1 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
        {t('my_account')}
      </h1>

      {/* Message Display */}
      {message && <div style={{ padding: '10px', backgroundColor: '#f8d7da', color: '#721c24', marginBottom: '10px' }}>{message}</div>}

      {/* Account Info */}
      <div className="bordered-text" style={{ padding: '10px', marginBottom: '20px' }}>
  <h2 style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px' }}>
    {t('account_info')}
  </h2>
  {editMode ? (
    <>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>
          {t('username')}
        </label>
        <input
          type="text"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>
          {t('country')}
        </label>
        <input
          type="text"
          value={newCountry}
          onChange={(e) => setNewCountry(e.target.value)}
          style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
        />
      </div>
      <button
        onClick={handleEditProfile}
        style={{
          padding: '3px 10px',
          fontSize: '14px',
          backgroundColor: '#E6E6E6',
          border: '1px solid #000000',
          cursor: 'pointer',
          marginRight: '10px',
        }}
      >
        {t('save')}
      </button>
      <button
        onClick={() => {
          setEditMode(false);
          setNewUsername(userInfo.username);
          setNewCountry(userInfo.country || '');
        }}
        style={{
          padding: '3px 10px',
          fontSize: '14px',
          backgroundColor: '#E6E6E6',
          border: '1px solid #000000',
          cursor: 'pointer',
        }}
      >
        {t('cancel')}
      </button>
    </>
  ) : (
    <>
      <p style={{ fontSize: '14px' }}><strong>{t('username')}:</strong> {userInfo.username}</p>
      <p style={{ fontSize: '14px' }}><strong>{t('country')}:</strong> {userInfo.country}</p>
      <button
        onClick={() => setEditMode(true)}
        style={{
          padding: '3px 10px',
          fontSize: '14px',
          backgroundColor: '#E6E6E6',
          border: '1px solid #000000',
          cursor: 'pointer',
        }}
      >
        {t('edit')}
      </button>
    </>
  )}
</div>


      {/* Change Password */}
      <div className="bordered-text" style={{ padding: '10px', marginBottom: '20px' }}>
        <h2 style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px' }}>{t('change_password')}</h2>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('new_password')}</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('confirm_password')}</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
        </div>
        <button
          onClick={handleChangePassword}
          style={{
            padding: '3px 10px',
            fontSize: '14px',
            backgroundColor: '#E6E6E6',
            border: '1px solid #000000',
            cursor: 'pointer',
          }}
        >
          {t('change')}
        </button>
      </div>

      {/* Listings */}
      <div className="bordered-text" style={{ padding: '10px', marginBottom: '20px' }}>
        <h2 style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px' }}>{t('my_listings')}</h2>
        {/* Active Listings */}
        <div>
          <h3 style={{ fontSize: '12px', fontWeight: 'bold' }}>{t('active_listings')}</h3>
          {listings.active.length === 0 ? (
            <p>{t('no_active_listings')}</p>
          ) : (
            <ul>
              {listings.active.map((listing) => (
                <li key={listing._id}>
                  <p style={{ fontSize: '12px' }}>
                    {listing.name} - {listing.category} - {listing.location}
                  </p>
                  <button
                    onClick={() => handleDeleteListing(listing._id)}
                    style={{
                      padding: '3px 10px',
                      fontSize: '14px',
                      backgroundColor: '#E6E6E6',
                      border: '1px solid #000000',
                      cursor: 'pointer',
                    }}
                  >
                    {t('delete')}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Inactive Listings */}
        <div>
          <h3 style={{ fontSize: '12px', fontWeight: 'bold' }}>{t('inactive_listings')}</h3>
          {listings.inactive.length === 0 ? (
            <p>{t('no_inactive_listings')}</p>
          ) : (
            <ul>
              {listings.inactive.map((listing) => (
                <li key={listing._id}>
                  <p style={{ fontSize: '12px' }}>
                    {listing.name} - {listing.category} - {listing.location}
                  </p>
                  <button
                    onClick={() => handleDeleteListing(listing._id)}
                    style={{
                      padding: '3px 10px',
                      fontSize: '14px',
                      backgroundColor: '#E6E6E6',
                      border: '1px solid #000000',
                      cursor: 'pointer',
                    }}
                  >
                    {t('delete')}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Deactivate Account */}
      <div className="bordered-text" style={{ padding: '10px' }}>
        <button
          onClick={handleDeactivate}
          style={{
            padding: '5px 15px',
            fontSize: '14px',
            backgroundColor: '#ff6666',
            border: '1px solid #ff3333',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          {t('deactivate_account')}
        </button>
      </div>
    </div>
  );
}

export default Account;

