import React from 'react';

const Privacy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#ffffff' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Privacy Policy</h1>
      
      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>1. Introduction</h2>
      <p style={{ fontSize: '16px' }}>
        This Privacy Policy explains how barterout collects, uses, and protects your personal information. By using our services, you agree to the terms outlined in this policy.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>2. Information We Collect</h2>
      <p style={{ fontSize: '16px' }}>
        We collect personal information when you create an account, list items, or interact with our platform. This may include your name, email address, phone number, and transaction details.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>3. How We Use Your Information</h2>
      <p style={{ fontSize: '16px' }}>
        We use your information to provide and improve our services, process transactions, communicate with you, and ensure compliance with our Terms and Conditions.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>4. Data Security</h2>
      <p style={{ fontSize: '16px' }}>
        We implement industry-standard security measures to protect your personal data. However, no method of transmission over the internet is 100% secure, so we cannot guarantee the absolute security of your data.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>5. Sharing Your Information</h2>
      <p style={{ fontSize: '16px' }}>
        We do not sell or rent your personal information to third parties. However, we may share your information with trusted partners who assist us in operating our platform, processing transactions, or providing customer support.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>6. Your Rights</h2>
      <p style={{ fontSize: '16px' }}>
        You have the right to access, update, or delete your personal information. You can also opt-out of receiving promotional emails by following the unsubscribe instructions in those emails.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>7. Cookies</h2>
      <p style={{ fontSize: '16px' }}>
        We use cookies to enhance your experience on our platform. Cookies are small files stored on your device that help us improve our services and remember your preferences.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>8. Changes to the Privacy Policy</h2>
      <p style={{ fontSize: '16px' }}>
        We may update this Privacy Policy periodically. Any significant changes will be communicated to you. Continued use of the platform constitutes acceptance of the revised policy.
      </p>

      <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>9. Contact Information</h2>
      <p style={{ fontSize: '16px' }}>
        If you have any questions about this Privacy Policy or how we handle your personal information, please contact us at <a href="mailto:support@barterout.com">support@barterout.com</a>.
      </p>
    </div>
  );
};

export default Privacy;

