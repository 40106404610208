import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Hero() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const { t } = useTranslation();

  const handleSearch = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    if (searchQuery.trim()) {
      queryParams.set('search', searchQuery);
    }
    if (selectedLocation.trim()) {
      queryParams.set('location', selectedLocation);
    }
    navigate(`/browse?${queryParams.toString()}`);
  };

  const locations = [t('all_locations'), 'Riga', 'Tallinn', 'Vilnius', 'Helsinki'];

  const categories = [
    'Electronics',
    'Clothing',
    'Books',
    'Antiques',
    'Arts',
    'Appliances',
    'Properties',
    'Jewelries',
    'Furniture',
    'Toys',
    'Sports',
    'Other'
  ];

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h1 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', margin: '0 0 10px 0' }}>
          {t('barterout')}: declutter. swap. barter.
        </h1>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder=""
            style={{
              width: '300px',
              padding: '3px',
              fontSize: '14px',
              border: '1px solid #000000',
              marginRight: '5px',
            }}
          />
          <select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            style={{
              padding: '3px',
              fontSize: '14px',
              border: '1px solid #000000',
              marginRight: '5px',
            }}
          >
            {locations.map((loc) => (
              <option key={loc} value={loc === t('all_locations') ? '' : loc}>
                {loc}
              </option>
            ))}
          </select>
          <button
            type="submit"
            style={{
              padding: '3px 10px',
              fontSize: '14px',
              backgroundColor: '#E6E6E6',
              border: '1px solid #000000',
              cursor: 'pointer',
            }}
          >
            {t('search_barterout')}
          </button>
        </form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '50px', flexWrap: 'wrap' }}>
        <div>
          <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>{t('barterout')}</h2>
          <ul>
            <li><Link to="/list" aria-label={t('create_a_posting')}>{t('create_a_posting')}</Link></li>
            <li><Link to="/browse" aria-label={t('browse_items')}>{t('browse_items')}</Link></li>
            <li><Link to="/suggestions" aria-label={t('trade_suggestions')}>{t('trade_suggestions')}</Link></li>
          </ul>
        </div>
        <div>
          <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>{t('categories')}</h2>
          <ul>
            {categories.map((category) => (
              <li key={category}>
                <Link to={`/browse?category=${category}`} aria-label={t(category.toLowerCase())}>{t(category.toLowerCase())}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>{t('help')}</h2>
          <ul>
            <li><Link to="/conditions" aria-label={t('conditions_of_use')}>{t('conditions_of_use')}</Link></li>
            <li><Link to="/privacy" aria-label={t('privacy_notice')}>{t('privacy_notice')}</Link></li>
            <li><Link to="/help" aria-label={t('help')}>{t('help')}</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Hero;

