import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import AuthGuard from './AuthGuard';
import { useTranslation } from 'react-i18next';
import i18n from './i18n'; // Removed axios import
import Hero from './Hero'; // Changed from 'Home' to 'Hero'
import Browse from './Browse';
import ListItem from './ListItem';
import Suggestions from './Suggestions';
import Conditions from './Conditions'; // Ensure this file exists or is renamed accordingly
import Privacy from './Privacy'; // Ensure this file exists or is renamed accordingly
import Login from './Login';
import Register from './Register';
import Account from './Account';
import { Helmet } from 'react-helmet';

function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const [loc, setLoc] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleSearch = () => {
    const queryParams = new URLSearchParams();
    if (search) queryParams.set('search', search);
    if (category) queryParams.set('category', category);
    if (loc) queryParams.set('location', loc);
    navigate(`/browse?${queryParams.toString()}`);
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const currentLang = i18n.language;

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Helmet>
        <title>{t('barterout')}</title>
        <meta name="description" content="barterout: Trade items in Riga, Tallinn, Vilnius, Helsinki. Browse, list, and swap electronics, clothing, books, and more." />
        <meta name="keywords" content="barterout, trade items, swap, Riga, Tallinn, Vilnius, Helsinki, electronics, clothing, books" />
        <link rel="canonical" href={`https://barterout.com/${currentLang}`} />
      </Helmet>

      <header style={{ backgroundColor: '#FFFFFF', padding: '10px', borderBottom: '1px solid #000000', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left Section: Navigation Links */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={{ fontSize: '16px', fontWeight: 'bold', textDecoration: 'none', color: '#000000', marginRight: '20px' }}>
            {t('barterout')}
          </Link>
          <Link to="/list" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000', marginRight: '10px' }}>
            {t('list')}
          </Link>
          <Link to="/suggestions" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000', marginRight: '10px' }}>
            {t('suggestions')}
          </Link>
          <Link to="/conditions" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000', marginRight: '10px' }}>
            {t('conditions')}
          </Link>
          <Link to="/privacy" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000', marginRight: '10px' }}>
            {t('privacy')}
          </Link>
        </div>

        {/* Middle Section: Log In / Sign Up or My Account / Sign Out */}
        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', alignItems: 'center' }}>
          {!isLoggedIn ? (
            <>
              <Link to="/login" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000', marginRight: '10px' }}>
                {t('sign_in')}
              </Link>
              <Link to="/register" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000' }}>
                {t('create_account')}
              </Link>
            </>
          ) : (
            <>
              <Link to="/account" style={{ fontSize: '14px', textDecoration: 'none', color: '#000000', marginRight: '10px' }}>
                {t('my_account')}
              </Link>
              <button
                onClick={handleSignOut}
                style={{
                  fontSize: '14px',
                  backgroundColor: '#E6E6E6',
                  border: '1px solid #000000',
                  padding: '3px 10px',
                  cursor: 'pointer',
                }}
              >
                {t('sign_out')}
              </button>
            </>
          )}
        </div>

        {/* Right Section: Language Selector */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <select
            value={currentLang}
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            style={{ fontSize: '14px', padding: '3px', border: '1px solid #000000' }}
          >
            <option value="en">{t('english')}</option>
            <option value="lv">{t('latvian')}</option>
            <option value="et">{t('estonian')}</option>
            <option value="lt">{t('lithuanian')}</option>
            <option value="fi">{t('finnish')}</option>
          </select>
        </div>
      </header>

      <main style={{ flex: 1, padding: '10px' }}>
        {/* Your search bar and other components here */}
        {location.pathname === '/' && (
          <div style={{ maxWidth: '500px', margin: '0 auto', padding: '10px' }}>
            <h1 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{t('search_barterout')}</h1>
            <div style={{ marginBottom: '10px' }}>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder=""
                style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
              >
                <option value="">{t('categories')}</option>
                <option value="Electronics">{t('electronics')}</option>
                <option value="Clothing">{t('clothing')}</option>
                <option value="Books">{t('books')}</option>
                <option value="Antiques">{t('antiques')}</option>
                <option value="Arts">{t('arts')}</option>
                <option value="Appliances">{t('appliances')}</option>
                <option value="Properties">{t('properties')}</option>
                <option value="Jewelries">{t('jewelries')}</option>
                <option value="Furniture">{t('furniture')}</option>
                <option value="Toys">{t('toys')}</option>
                <option value="Sports">{t('sports')}</option>
                <option value="Other">{t('other')}</option>
              </select>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <select
                value={loc}
                onChange={(e) => setLoc(e.target.value)}
                style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
              >
                <option value="">{t('all_locations')}</option>
                <option value="Riga">Riga</option>
                <option value="Tallinn">Tallinn</option>
                <option value="Vilnius">Vilnius</option>
                <option value="Helsinki">Helsinki</option>
              </select>
            </div>
            <button
              onClick={handleSearch}
              style={{
                width: '100%',
                padding: '3px',
                fontSize: '14px',
                backgroundColor: '#E6E6E6',
                border: '1px solid #000000',
                cursor: 'pointer',
              }}
            >
              {t('search_barterout')}
            </button>
          </div>
        )}

        <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/browse" element={<Browse />} />
            <Route path="/conditions" element={<Conditions />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
        <Route element={<AuthGuard />}>
          <Route path="/list" element={<ListItem />} />
          <Route path="/suggestions" element={<Suggestions />} />
          <Route path="/account" element={<Account />} />
        </Route>
        <Route path="*" element={<navigate to="/" replace />} />
      </Routes>
      </main>

      {/* Footer */}
      <footer style={{
        backgroundColor: '#f8f8f8',
        padding: '10px',
        textAlign: 'center',
        borderTop: '1px solid #e0e0e0',
      }}>
        <p style={{ fontSize: '14px', color: '#000' }}>
          &copy; 2025 barterout. All Rights Reserved.
        </p>
      </footer>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

