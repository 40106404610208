import React, { useState } from 'react';
import axios from './axiosInstance';
import Breadcrumb from './Breadcrumb';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';

function ListItem() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [photo, setPhoto] = useState(null);
  const [contactInfo, setContactInfo] = useState('');
  const [message, setMessage] = useState('');
  const [contactError, setContactError] = useState('');
  const { t } = useTranslation();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;

  const validateContactInfo = (value) => {
    if (!value) return true;
    if (emailRegex.test(value) || phoneRegex.test(value)) {
      setContactError('');
      return true;
    }
    setContactError(t('invalid_contact_info'));
    return false;
  };

  const handleImageCompression = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Image compression failed:', error);
      return file;
    }
  };

  const handleSubmit = async () => {
    if (!validateContactInfo(contactInfo)) {
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('location', location);
    formData.append('contactInfo', contactInfo);

    if (photo) {
      const compressedPhoto = await handleImageCompression(photo);
      formData.append('photo', compressedPhoto);
    }

    try {
      await axios.post('/items', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(t('item_listed'));
    } catch (error) {
      setMessage(t('failed_to_list_item'));
    }
  };

  return (
    <div style={{ padding: '10px', maxWidth: '100%' }}>
      <Breadcrumb />
      <h2 className="bordered-text" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
        {t('create_a_posting')}
      </h2>
      <div className="bordered-text" style={{ maxWidth: '500px', padding: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('item_name')}</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder=""
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('description')}</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder=""
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000', height: '100px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('category')}</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          >
            <option value="">{t('select_category')}</option>
            <option value="Electronics">{t('electronics')}</option>
            <option value="Clothing">{t('clothing')}</option>
            <option value="Books">{t('books')}</option>
            <option value="Antiques">{t('antiques')}</option>
            <option value="Arts">{t('arts')}</option>
            <option value="Appliances">{t('appliances')}</option>
            <option value="Properties">{t('properties')}</option>
            <option value="Jewelries">{t('jewelries')}</option>
            <option value="Furniture">{t('furniture')}</option>
            <option value="Toys">{t('toys')}</option>
            <option value="Sports">{t('sports')}</option>
            <option value="Other">{t('other')}</option>
          </select>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('location')}</label>
          <input
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="e.g., Riga, Tallinn"
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>
            {t('contact_info')} ({t('optional')})
          </label>
          <input
            value={contactInfo}
            onChange={(e) => {
              setContactInfo(e.target.value);
              validateContactInfo(e.target.value);
            }}
            placeholder={t('phone_or_email')}
            style={{ width: '100%', padding: '3px', fontSize: '14px', border: '1px solid #000000' }}
          />
          {contactError && (
            <p style={{ fontSize: '12px', color: 'red', marginTop: '5px' }}>{contactError}</p>
          )}
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', fontSize: '14px', marginBottom: '2px' }}>{t('photo')}</label>
          <input
            type="file"
            onChange={(e) => setPhoto(e.target.files[0])}
            style={{ fontSize: '14px' }}
            aria-label={t('photo')}
          />
        </div>
        <button
          onClick={handleSubmit}
          style={{
            padding: '3px 10px',
            fontSize: '14px',
            backgroundColor: '#E6E6E6',
            border: '1px solid #000000',
            cursor: 'pointer',
          }}
        >
          {t('continue')}
        </button>
        {message && (
          <p style={{ marginTop: '10px', fontSize: '14px', color: message === t('item_listed') ? 'green' : 'red' }}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
}

export default ListItem;
