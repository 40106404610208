import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Breadcrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { t } = useTranslation();

  // Map URL path segments to translation keys
  const pathToTranslationKey = {
    list: 'create_a_posting',
    browse: 'browse_items',
    chat: 'chat',
    suggestions: 'trade_suggestions',
    conditions: 'conditions_of_use',
    privacy: 'privacy_notice',
    help: 'help',
    login: 'sign_in',
    register: 'create_account',
  };

  return (
    <nav style={{ fontSize: '12px', margin: '10px 0' }}>
      <Link to="/">{t('barterout')}</Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        // Use the mapped translation key, or fall back to the path name
        const translationKey = pathToTranslationKey[name] || name;
        return (
          <span key={name}>
            <span style={{ margin: '0 5px' }}>></span>
            {isLast ? (
              <span style={{ textTransform: 'lowercase' }}>{t(translationKey)}</span>
            ) : (
              <Link to={routeTo} style={{ textTransform: 'lowercase' }}>{t(translationKey)}</Link>
            )}
          </span>
        );
      })}
    </nav>
  );
}

export default Breadcrumb;
